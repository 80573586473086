@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    font-family: 'Montserrat';
}
body {
    margin: 0;
}
p {
    font-size: 16px;
    line-height: 27px;
    color: #151724;
}
.alice-carousel {
    float: left;
    width: 100%;
}
.headh2 {
    font-size: 55px;
    margin: 0 0 90px;
    position: relative;
    transition: all 0.5s ease;
    padding-bottom: 10px;
}
.client img {
    filter: grayscale(1);
    transition: all 0.5s ease;
    position: relative;
}

.client img:hover {
    filter: grayscale(0);
}

.headh2:After {
    content: "";
    position: absolute;
    width: 97px;
    height: 7px;
    background: #F84B00;
    border-radius: 10px;
    left: 0;
    bottom: -13px;
}
.navigation {
    width: 100%;
    float: left;
    position: absolute;
    padding: 16px 0 0 0;
}
.container {
    width: 1170px;
    margin: 0 auto;
}

.sitelogo {
    width: 20%;
    float: left;
}

.nav-menu {
    width: 80%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nav-menu ul {
    margin: 0;
    padding: 0;
}

.nav-menu ul li {
    list-style: none;
    float: left;
    padding: 0 10px;
}

.nav-menu ul li a {
    color: #fff;
    font-size: 16px;
    font-family: montserrat;
    font-weight: bold;
    line-height: 82px;
    text-decoration: none;
    padding: 10px 8px;
    transition: all 0.5s ease;
}

.nav-menu ul li a:hover {
    color: #F84B00;
    font-weight: bold;
    border-bottom: 4px solid #F84B00;
}

.banner {
    width: 100%;
    float: left;
    background-image: url(././images/banner.png);
    background-size: cover;
    background-position: bottom;
    height: 803px;
}
.banner-content {
    width: 100%;
    margin: 23% 0 0 0;
}

.banner-content h1 {
    font-size: 82px;
    font-weight: bold;
    color: #fff;
    font-family: montserrat;
    text-align: center;
    line-height: 13px;
    position: relative;
}
.banner-content h1:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 17px;
    background: #f84b00;
    right: 37.5%;
    top: -30px;
    border-radius: 40px;
}

.banner-content h1 span {
    color: #F84B00;
}

.banner-content h2 {
    font-size: 56px;
    font-family: MONTSERRAT;
    color: #fff;
    text-align: CENTER;
    font-weight: 500;
    padding: 0;
    margin: -7px 0 0 0;
}
.Services {
    width: 100%;
    float: left;
    margin: -299px 0 0 0;
    padding: 0 0 70px 0;
}

.serviceimg {
    padding: 50px;
    background: #fff;
    box-shadow: 0 3px 15px #00000024;
    border-radius: 33px;
    position: relative;

}

.contactform {
    width: 100%;
    float: left;
    margin: 6% 0 -18.5% 0;
    position: relative;
}

.form {
    width: 100%;
    padding: 30px;
    background: #fff;
    box-shadow: 0 3px 15px #0000002b;
    border-radius: 33px;
    float: left;
}

.form h2 {
    font-size: 62px;
    text-align: center;
    font-family: montserrat;
    font-weight: bold;
    line-height: 30px;
    position: relative;
}

.form h2:before {
    content: '';
    position: absolute;
    width: 97px;
    height: 7px;
    background: #f84b00;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 40px;
}

.half {
    width: 30.3%;
    float: left;
    margin-right: 40px;
}

.half:nth-child(3) {
    margin-right: 0 !important;
}

.half:nth-child(2) {
    margin-right: 40px;
}
form {
    width: 100%;
    float: left;
    margin: 11px 0 0 0;
}
.form .half input, .form textarea {
    width: 97%;
    padding: 20px 13px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
    border: none;
    background: #fff;
    box-shadow: 0 3px 8px #00000036;
    border-radius: 5px;
    resize: none;
    outline: none;
}
section {
    width: 100%;
    float: left;
}

.first-sec , .secnd-sec , .third-sec , .forth-sec , .fifth-sec {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.imgside {
    width: 47%;
    float: left;
    z-index: 2;
    /* padding: 70px 0px; */
    position: relative;
}

.contside {
    width: 53%;
    float: left;
    box-sizing: border-box;
    z-index: 2;
    padding: 70px 0px 70px 50px;
    position: relative;
}

.contside h2 {
    font-size: 55px;
    margin: 0 0 40px;
    position: relative;
    transition: all 0.5s ease;
}

.contside h2:before {
    content: "";
    position: absolute;
    width: 97px;
    height: 7px;
    background: #F84B00;
    border-radius: 10px;
    left: 0;
    bottom: -13px;
}

.contside p {
    color: #151724;
    font-size: 16px;
    line-height: 27px;
}

.imgright {
    text-align: right;
}

.imgleft {
    text-align: left;
}

.forth-sec .contside h2 {
    margin: 0 -188px 40px 0px;
}

.contside h2:after {
    content: "";
    position: absolute;
    background: url(./images/01.png)no-repeat;
    background-size: contain;
    width: 75px;
    height: 75px;
    left: -100px;
    top: -20px;
}

.secnd-sec .contside h2:after {
    background: url(./images/02.png)no-repeat;
    top: 13px;
    left: -120px;
}

.third-sec .contside h2:after {
    background: url(./images/03.png)no-repeat;
    top: -123px;
    left: -170px;
}

.forth-sec .contside h2:after {
    background: url(./images/04.png)no-repeat;
}

.fifth-sec .contside h2:after {
    background: url(./images/05.png)no-repeat;
  top: -99px;
}

.first-sec {
    padding: 90px 0;
}
.first-sec .imgside:after {
    content: "";
    position: absolute;
    background: url(./images/1-before.png)no-repeat;
    background-size: contain;
    width: 670px;
    height: 533px;
    top: 0;
    right: -16%;
}

.secnd-sec:before {
    content: "";
    position: absolute;
    background: url(./images/2-before.png)no-repeat;
    background-size: 100% 100%;
    width: 1213px;
    height: 89%;
    bottom: 0;
    left: -32px;
}

.third-sec:after {
    content: "";
    position: absolute;
    background: url(./images/3-before.png)no-repeat;
    background-size: contain;
    width: 1190px;
    height: 100%;
}

.forth-sec:before {
    content: "";
    position: absolute;
    background: url(./images/4-before.png)no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    left: 0;
    top: 10px;
}

.form input[type="submit"] {
    padding: 0px 29px;
    background: #f84b00;
    border: none;
    color: #fff;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    border-radius: 5px;
    line-height: 57px;
    outline: none;
    float: right;
    margin: 10px 10px 10px 0;
    cursor: pointer;
    transition: all 0.5s ease;
}
.client {
    margin: 20px 0;
}
footer {
    width: 100%;
    float: left;
    background-image: url(./images/footer.png);
    background-size: cover;
    background-position: top;
}

.footerlogo {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35% 0 0 0;
}

.copyright {
    width: 100%;
    float: left;
    margin: 5.6% 0 0 0;
    border-top: 1px solid #ffffff36;
    padding: 8px 0;
}

.copyright p {
    text-align: center;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
}
.footerlogo img {
    width: 100%;
    max-width: 244px;
}

.serviceimg:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    background: #00000047;
    top: 0;
    left: 0;
    border-radius: 33px;
    z-index: 0;
    transition: all 0.5s ease;
}

.serviceimg img {
    width: 100%;
    position: relative;
    z-index: 9;
    transition: all 0.5s ease;
}

.serviceimg:hover:before {
    height: 100%;
    width: 100%;
}



.imgside:hover .img img{
    animation: myfirst 8s 2;
    animation-direction: alternate;
}
@keyframes myfirst {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-50px); }
    100% { transform: translateY(0); }
}

.third-sec {
    padding-bottom: 0px;
    padding-top: 11px;
}
.serviceimg:hover img {
    transform: translateY(-18px);
}
.form input[type="submit"]:hover {
    background: #cc4910;
}
.contside:hover h2 {
    color: #807a86;
}

.alice-carousel{
    margin:20px auto
}
.alice-carousel__next-btn, .alice-carousel__prev-btn, .alice-carousel__dots{
    display: none !important;
}
.banner .container {
    height: 100%;
}

.innerbaner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #f84b00;
}

.innerbaner h1 {
    font-size: 82px;
}

.innerbaner .headh2:After {
    right: 0;
    margin: auto;
}

.innerbaner h1 span {
    color: #fff;
}

.h2mrgn {
    margin-bottom: 35px;
}

/* about page */


.teambio {
    padding: 30px;
    display: flex;
    align-items: center;
    background: transparent;
    transition: all 0.5s ease;
    border-radius: 8px;
    top: 0;
    position: relative;
}

section.teammember {
    margin: 30px 0 0;
}

.teambio .imgsec {
    width: 35%;
    flex: 0 0 35%;
}

.imgcontent h3 {
    font-size: 40px;
    margin: 0 0 10px;
}

.imgcontent  span {
    font-size: 20px;
    color: #F84B00;
    font-weight: 600;
}

.teambio.teamrev {flex-direction: row-reverse;}

.teambio.teamrev .imgsec {
    text-align: right;
}

.teambio.teamrev  .imgcontent {
    text-align: right;
}

.imgcontent {
    padding: 30px;
}

.teambio:hover {
    top: -6px;
    background: rgb(0 0 0 / 5%);
    box-shadow: 0 12px 9px 0px rgb(0 0 0 / 19%);
}

/* Services page */


section.servicesmain {
    margin: 75px 0 0;
}

section.servicesmain .container {
    display: flex;
    flex-wrap: wrap;
}

.servicesmain .container .servbox {
    flex: 0 0 50%;
    width: 50%;
    padding: 30px;
    box-sizing: border-box;
    transition: 0.5s all ease;

}
.servicesmain .container .servbox:hover {
    top: -6px;
    background: rgb(0 0 0 / 5%);
    box-shadow: 0 12px 9px 0px rgb(0 0 0 / 19%);
}

.servicesmain .container .servbox h2.headh2 {
    font-size: 40px;
}

.servicesmain .container .servbox.servboxwth {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    margin: 20px 0;
    align-items: center;

}

.seimg {
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
}
.servicesmain .container .servbox.servboxwth h2.headh2 {
    font-size: 60px;
}
.servicesmain .container .servbox.servboxwth .secont {
    margin-left: 30px;
}

.seimg img {
    width: 70%;
}

.servicesmain .container .servbox.servboxwth .seimg {
    text-align: center;
    width: 45%;
    flex: 0 0 45%;
}

.servicesmain .container .servbox.servboxwth .seimg img {
    width: 81%;
}
.servicesmain .container .servbox.servboxwth .animated {
    display: flex;
}
.carebx {
    display: flex;
    align-items: center;
    border: 2px solid #151724;
    border-radius: 8px;
    transition: all 0.5s ease;

}

.cimgbxx {
    background: #151724;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 6px;
}

.carebx h2 {
    margin: 0;
    font-size: 36px;
    font-weight: 500;
}
.carcontent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    align-items: center;
}


.carcontent a {
    padding: 0px 29px;
    background: #f84b00;
    border: none;
    color: #fff;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    border-radius: 5px;
    line-height: 57px;
    outline: none;
    float: right;
    margin: 10px 10px 10px 0;
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: none;
    min-width: 10%;
    text-align: center;
}

.carcontent a:hover {
    background: #fff;
    color: #f84b00;
}

.carebx:hover {
    background: #151724;
    color: #fff;
}
section.carersmain {
    margin: 45px auto;
}
.centerh2 {
    text-align: center;
}

.centerh2:after {
    right: 0;
    margin: auto;
}.carebx:hover .cimgbxx {
    background: #fff;
}

.carebx .cimgbxx {
    transition: all 0.5s ease;
}
.carebx h2 span {
    font-size: 16px;
    float: left;
    font-weight: bold;
    color: #f84b00;
    margin-top: 3px;
}
.col-6 {
    width: 50%;
    float: left;
}

ul.continfo {
    display: flex;
    flex-direction: column;
    margin: 110px auto;
    justify-content: center;
}

ul.continfo li {
    display: flex;
    list-style: none;
    align-items: center;
    margin-bottom: 20px;
}

ul.continfo li .icnbx {
    background: #F84B00;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    transition: all 0.5s ease;
    margin-right: 15px;
    flex: 0 0 70px;
}

ul.continfo li:hover .icnbx {
    background: #161825;
}

.contctcont h3 {
    margin: 0px 0 7px;
    font-size: 24px;
}

.contctcont p,.contctcont a {
    color: #494949;
    font-size: 18px;
    text-decoration: none;
}

@media all and (max-width: 767px) and (min-width: 320px){
    .container {
        width: 300px;
    }
    
    .secnd-sec:before {
        display: none;
    }
    
    .third-sec:after {
        display: none;
    }
    
    .first-sec .imgside:after {
        display: none;
    }
    
    .first-sec, .secnd-sec, .third-sec, .forth-sec, .fifth-sec {
        flex-wrap: wrap;
        flex-direction: column;
    }
    
    .imgside img {
        width: 100%;
    }
    
    .contside h2 {
        font-size: 20px;
    }
    
    .serviceimg {
        padding: 16px;
    }
    
    .banner-content h2 {
        font-size: 30px;
    }
    
    .banner {
        height: 450px;
    }
    
    .Services {
        margin: -109px 0 0 0;
    }
    .wrapper {
        overflow-x: hidden;
    }
    
    .Services .container {
        width: 87%;
    }
    
    .serviceimg:hover img {
        transform: translateY(-3px);
    }
    
    .contside h2:after {
        left: -47px !important;
        width: 38px !IMPORTANT;
        height: 38px !IMPORTANT;
        top: -7px !important;
        background-size: contain !IMPORTANT;
    }
    
    .contside {
        width: 90%;
        padding: 25px 0 0 30px;
    }
    
    .contside h2:before {
        height: 4px;
        width: 40px;
    }
    
    .contside p {
        font-size: 14px;
    }
    
    .secnd-sec, .forth-sec {flex-direction: column-reverse;}
    .first-sec {
        padding: 30px 0;
    }
    
    .Services {
        padding-bottom: 15px;
    }
    .first-sec:after, .secnd-sec:after, .third-sec:after, .forth-sec:after {
        content: '';
        height: 100%;
        width: 2px;
        position: absolute;
        left: 15px;
        background: url(./images/singleline.png);
        top: 155px;
        background-size: 100% 100%;
        background-repeat: repeat !IMPORTANT;
        bottom: 0;
        right: 57px;
        display: block !important;
    }
    .forth-sec:before{
        display: none;
    }
    .headh2 {
        font-size: 36px;
    }
    
    .headh2:After {
        height: 6px;
        width: 53px;
    }
    
    .form h2 {
        font-size: 36px;
    }
    
    .form h2:before {
        width: 50px;
        height: 4px;
    }
    
    .form .half input, .form textarea {
        box-sizing: border-box;
        font-size: 12px;
    }
    
    .form {
        box-sizing: border-box;
    }
    
    .half {
        width: 100%;
        margin-right: 0;
    }
    
    .half:nth-child(2) {
        margin-right: 0;
    }
    
    form {
        margin-top: 20px;
    }
    
    .check label {
        font-size: 12px;
        width: 90%;
    }
    
    .check {
        display: flex;
        flex-wrap: wrap;
    }
    
    .form input[type="submit"] {
        padding: 17px;
        line-height: 17px;
        font-size: 14px;
    }
    
    .copyright p {
        font-size: 14px;
    }
    
    .footerlogo img {
        width: 150px;
    }
    
    .contactform {
        margin: 6% 0 -24.5% 0;
    }
    
    .client li.alice-carousel__stage-item {
        padding: 0 23px;
    }.headh2 {
    font-size: 36px;
}

.headh2:After {
    height: 6px;
    width: 53px;
}

.form h2 {
    font-size: 36px;
}

.form h2:before {
    width: 50px;
    height: 4px;
}

.form .half input, .form textarea {
    box-sizing: border-box;
    font-size: 12px;
}

.form {
    box-sizing: border-box;
}

.half {
    width: 100%;
    margin-right: 0;
}

.half:nth-child(2) {
    margin-right: 0;
}

form {
    margin-top: 20px;
}

.check label {
    font-size: 12px;
    width: 90%;
}

.check {
    display: flex;
    flex-wrap: wrap;
}

.form input[type="submit"] {
    padding: 17px;
    line-height: 17px;
    font-size: 14px;
}

.copyright p {
    font-size: 14px;
}

.footerlogo img {
    width: 150px;
}

.contactform {
    margin: 6% 0 -24.5% 0;
}

.client li.alice-carousel__stage-item {
    padding: 0 23px;
}
.client img {
    width: 100%;
    text-align: center;
}
.menu-icon {
    float: right;
    background: url(./images/hamburger.png);
    width: 40px;
    height: 40px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-size: cover;
    filter: invert(1);
}

.nav-menu {
    display: none;
}

.navigation .container {
    display: flex;
    align-items: center;
}

.sitelogo {
    width: 56%;
}
.banner-content {
    margin: 58% 0 0 0;
}
.nav-menu {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 122px;
    z-index: 99;
    background: #3a3c49;
    left: 0;
    right: 0;
}

.nav-menu ul li {width: 100%;text-align: center;padding: 0;box-sizing: border-box;}
.header {
    width: 100%;
    height: 100%;
    float: left;
    position: relative;
    z-index: 999;
}.banner-content h1 {
    font-size: 40px;
    text-align: center;
}

.banner-content h1:before {
    right: 26%;
    top: -13px;
    width: 10px;
    height: 10px;
}
.innerbaner h1 {
    font-size: 46px;
}

.teambio {
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.imgcontent h3 {
    font-size: 26px;
}

.imgcontent span {
    font-size: 16px;
}

.imgcontent {
    padding: 0;
    margin-top: 20px;
}

.teambio .imgsec {
    width: 100%;
    flex: 0 0 100%;
}

.teambio .imgsec img {
    width: 100%;
}.servicesmain .container .servbox {
    flex: 0 0 100%;
    width: 100%;
}

.servicesmain .container .servbox h2.headh2 {
    font-size: 28px;
}

p {
    font-size: 14px;
    line-height: 24px;
}

.servicesmain .container .servbox.servboxwth .animated {
    flex-wrap: wrap;
}

.servicesmain .container .servbox.servboxwth .seimg {
    width: 100%;
    flex: 0 0 100%;
}

.servicesmain .container .servbox.servboxwth .secont {margin-left: 0;}

.servicesmain .container .servbox.servboxwth h2.headh2 {
    font-size: 28px;
}
.seimg img {
    width: 100%;
}
.carebx {
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 25px 0 15px;
}

.carcontent {
    flex-wrap: wrap;
}

.carebx h2 {
    font-size: 24px;
}

.carebx h2 span {
    font-size: 13px;
}

.carcontent a {
    float: none;
    margin: 22px auto 0;
}
.col-6 {
    width: 100%;
}
.mapimg img{
    width: 100%;
}

ul.continfo {
    margin: 20px auto;
    padding: 0;
}

.contctcont h3 {
    font-size: 20px;
}

.contctcont p, .contctcont a {
    font-size: 14px;
}

ul.continfo li .icnbx {
    width: 55px;
    height: 55px;
    flex: 0 0 55px;
}

}
@media all and (max-width: 1023px) and (min-width: 768px){
    .banner-content h1:before {
        right: 29%;
    }
    .nav-menu ul li a {
        font-size: 14px;
    }
    
    .nav-menu ul li {
        padding: 8px 4px;
    }
    
    .banner {
        height: 615px;
    }
    
    .Services {
        margin: -215px 0 0 0;
    }
    
    .banner-content h2 {
        font-size: 34px;
    }
    
    .banner-content {
        margin: 30% 0 0 0;
    }
    .container {
        width: 700px;
    }
    
    .third-sec:after {
        display: none;
    }
    
    .secnd-sec:before {
        display: none;
    }
    
    .form {
        box-sizing: border-box;
    }
    
    .form .half input, .form textarea {
        box-sizing: border-box;
        width: 100%;
    }
    
    .half {
        margin-right: 2%;
        width: 32%;
    }
    
    .half:nth-child(2) {
        margin-right: 2%;
    }
    
    .check label {
        font-size: 13px;
    }
    
    .imgside img {
        width: 100%;
    }
    
    .contside h2 {
        font-size: 34px;
    }
    
    .contside h2:before {
        width: 75px;
        height: 5px;
    }
    
    .contside h2:after {
        width: 65px !IMPORTANT;
        height: 65px !IMPORTANT;
        top: -6px !IMPORTANT;
        left: -81px !IMPORTANT;
        background-size: contain !IMPORTANT;
    }
    
    .first-sec .imgside:after {
        display: none;
    }
    
    .forth-sec:before {
        display: none;
    }
    
    .first-sec {
        padding: 20px;
        box-sizing: border-box;
    }
    
    .headh2 {
        font-size: 40px;
    }
    
    .headh2:After {
        width: 75px;
        height: 5px;
    }
    
    .form h2 {
        font-size: 50px;
    }
    
    .form h2:before {
        width: 75px;
        height: 5px;
    }
    .teambio .imgsec {
        width: 42%;
        flex: 0 0 42%;
    }
    
    .imgcontent h3 {font-size: 26px;}
    
    .imgcontent span {
        font-size: 16px;
    }
    
    .teambio .imgsec img {
        width: 100%;
    }
    
    .imgcontent p {
        font-size: 14px;
        line-height: 22px;
    }
    
    .imgcontent {
        padding: 20px;
    }.innerbaner h1 {
        font-size: 70px;
    }.servicesmain .container .servbox h2.headh2 {
        font-size: 26px;
    }
    
    .servicesmain .container .servbox.servboxwth h2.headh2 {
        font-size: 36px;
    }
    .carebx h2 {
        font-size: 22px;
    }
    
    .carebx h2 span {
        font-size: 12px;
    }
    
    .carcontent a {min-width: 16%;font-size: 14px;padding: 0 14px;line-height: 44px;}
    .mapimg img {
        width: 100%;
    }
    
    ul.continfo {
        margin: 20px auto;
        padding-left: 18px;
    }
    
    .contctcont h3 {
        font-size: 20px;
    }
    
    .contctcont p, .contctcont a {
        font-size: 14px;
    }
    
    ul.continfo li .icnbx {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
    }
}
@media all and (max-width: 1365px) and (min-width: 1024px){
    .mapimg img {
        width: 100%;
    }
    
    ul.continfo {
        margin: 38px auto;
    }
    
    .contctcont h3 {
        font-size: 20px;
    }
    
    .contctcont p, .contctcont a {
        font-size: 14px;
    }
    .banner-content h1 {
        font-size: 70px;
    }
    
    .banner-content h2 {
        font-size: 41px;
    }
    
    .banner-content h1:before {
        right: 36.3%;
        top: -28px;
    }
    
    .banner {
        height: 700px;
    }
    .container {
        width: 930px;
    }
    .half {
        width: 30.2%;
    }
    .form {
        box-sizing: border-box;
    }
    
    .form .half input, .form textarea {
        box-sizing: border-box;
        width: 100%;
    }
    
    .third-sec:after {display: none;}
    
    .first-sec .imgside:after {
        display: none;
    }
    
    .secnd-sec:before {
        display: none;
    }
    
    .forth-sec:before {
        display: none;
    }
    
    .contside h2 {
        font-size: 44px;
    }
    
    .contside h2:after {
        width: 65px !IMPORTANT;
        height: 65px !important;
        top: 0px !important;
        left: -82px !important;
        background-size: contain !IMPORTANT;
    }
    
    .first-sec {
        padding: 20px 0;
    }
    
    .imgside img {
        width: 100%;
    }section.stepss {
        padding: 0 50px;
        box-sizing: border-box;
    }.teambio .imgsec img {
        width: 100%;
    }
    
    .imgcontent h3 {
        font-size: 36px;
    }
    .innerbaner h1 {
        font-size: 70px;
    }
    .imgcontent span {
        font-size: 18px;
    }.servicesmain .container .servbox h2.headh2 {
        font-size: 29px;
    }
    
    .headh2:After {
        width: 70px;
        height: 5px;
    }
    
    .servicesmain .container .servbox.servboxwth h2.headh2 {
        font-size: 40px;
    }
    .carebx h2 {
        font-size: 28px;
    }
    
    .carebx h2 span {
        font-size: 12px;
    }
    
    .carcontent a {min-width: 12%;font-size: 15px;padding: 0 20px;line-height: 44px;}

}
